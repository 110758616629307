<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <span class="sp">场所：</span>
            <select class="form_select" v-model="selectItem" @change="selectFn($event)">
            <!--选择项的value值默认选择项文本 可动态绑定选择项的value值 更改v-model指令绑定数据-->
                <option v-for="item in items" :key="item.site_code" :value="item.site_code">{{item.site_name}}</option>
            </select>
          </div>
        </div>
        <div class="button primary mt" @click="submit">确定</div>
        <div class="button1 primary mt" @click="goback">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';

export default {
  created() {
    this.type = this.$route.query.type;
    this.unionid = this.$route.query.unionid;
    this.srole = this.$route.query.srole;
    this.query();
  },
  data() {
    return {
      type: '',
      unionid: '',
      srole: true,
      selectItem: '',
      state:false,
      items: []
    };
  },
  methods: {
    goback() {
      this.$router.replace({path:'/admin/admin_index', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
    },
    async query() {
      let { data } = await this.axios.get('/ctid/TldjService/dj_sitelist');
      //this.items = data.data;
      var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
      this.items = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;
    },
    selectFn(e) {
      //Toast(e.target.value); 
      // console.log(e)
      // console.log(e.target.selectedIndex) // 选择项的index索引
      // console.log(e.target.value) // 选择项的value
    },
    async submit() {
      if(this.state){
        return false;
      }
      this.state = true;
      if (isEmpty(this.selectItem)) {
        this.state = false;
        Toast('选择切换场所!');
        return;
      }
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_optsite',
      qs.stringify({ 
        sitecode: this.selectItem 
      })
      );
      if (data.flag === '0') {
        this.state = false;
        Toast(data.message);
        this.type = '1';
        this.$router.replace({path:'/admin/admin_index', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
      } else {
        this.state = false;
        Dialog({ message: data.message });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
}
.content {
  margin-top: 60px;
}
.container {
  padding: 0px 0px 20px 0px;
}
.form_select {
  /* 清除默认边框 */
  border: 0;
  /* 清除默认的箭头样式 */
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /* 右侧添加小箭头的背景图 */
  background: url('/img/arrow_1.png') 98% center no-repeat;
  background-size: 26px;
  width: 100%;
  height: 60px;
  background-color: transparent;
  font-size: 30px;
  color: rgb(136, 134, 134);
  line-height: 60px;
  vertical-align: middle;
  //direction: rtl;
  padding-right: 40px;
} 
select:focus { outline: none; }

.sp {
  margin-left: 5px;
  font-size: 30px;
  width: 180px;
  height: 60px;
  vertical-align: middle;
  line-height: 60px;
}
.button1 {
  width: 100%;
  height: 88px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  line-height: 88px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  cursor: pointer;
  &.primary {
    background: linear-gradient(90deg, rgba(31, 214, 199, 1) 0%, rgba(16, 189, 241, 1) 100%);
    color: #fff;
  }
  &:active {
    opacity: 0.8;
  }
  &.mb {
    margin-top: 54px;
  }
  &.mt {
    margin-top: 20px;
  }
}
</style>